/* Mixins */
.camera-realplay-component .left-card-wrapper {
  height: 50%;
  overflow-y: auto;
}
.camera-realplay-component .left-card-wrapper .ant-card-body {
  height: calc(100% - 48px);
  overflow-y: auto;
}
.camera-realplay-component .jtl-video-wrapper {
  width: 100%;
  height: calc(100vh - 48px - 41px);
}
.camera-realplay-component .jtl-tree {
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.camera-realplay-component .jtl-tree .ant-tree-node-content-wrapper {
  width: calc(100% - 20px);
}
.camera-realplay-component .jtl-tree .ant-tree-node-content-wrapper .ant-tree-title .jtl-tooltip-component {
  display: inline-block;
  width: calc(100% - 18px);
}
.camera-realplay-component .jtl-tree .ant-tree-node-content-wrapper .tree-item-action-dot {
  margin-right: -4px;
}
.camera-realplay-component .jtl-tree .ant-tree-node-selected {
  font-weight: bold;
}
.camera-realplay-component .ant-card .ant-card-body {
  padding-bottom: 0;
}
.camera-realplay-component .ant-tree-title {
  display: block;
}
.camera-realplay-component .dark-card {
  background: #252B36;
  border-radius: 0;
}
.camera-realplay-component .dark-card .ant-tree-title, .camera-realplay-component .dark-card .ant-tree-switcher {
  color: rgba(255, 255, 255, 0.65);
}
.camera-realplay-component .dark-card .ant-tree-node-content-wrapper.ant-tree-node-selected, .camera-realplay-component .dark-card .ant-tree-node-content-wrapper:hover {
  color: #fffbf0;
  background-color: var(--primaryColor);
}
.popover-button {
  margin-top: 8px;
}
.popover-cancel-button {
  margin-right: 8px;
}
.tree-search-wrapper {
  height: calc(100% - 32px);
  overflow-y: auto;
  overflow-x: hidden;
}